import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import Heading from '@greenberry/salal/components/Heading';
import HeroV4 from '@greenberry/salal/components/Hero/v4';
import Section from '@greenberry/salal/components/Section';
import MainWrapper from '@greenberry/salal/components/MainWrapper';
import Introduction from '@greenberry/salal/components/Introduction';
import Button from '@greenberry/salal/components/Button';
import theme from '@greenberry/salal/theme';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

const CtaWrapper = styled.div`
  display: inline-block;
  margin: ${theme.spacing('l')} 0;
`;

const NotFound = () => {
  return (
    <Layout>
      <SEO title="Niet gevonden" tags={[]} />
      <HeroV4
        content={
          <Heading component="h1" size="xxxl" weight="semibold">
            Helaas!
          </Heading>
        }
      />
      <MainWrapper>
        <Section size="1">
          <Introduction
            title="Deze pagina bestaat niet"
            text={`
De pagina die u zoekt is mogelijk verplaatst of verwijderd. Probeer het (later) nog eens, klik op ‘terug’ in uw browser of keer terug naar de homepage. Onze excuses voor het ongemak.
`}
          />
          <CtaWrapper>
            <Link to="/">
              <Button appearance="primary">
                <span>Terug naar homepage</span>
              </Button>
            </Link>
          </CtaWrapper>
        </Section>
      </MainWrapper>
    </Layout>
  );
};

export default NotFound;
